var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-checkbox-group",
    {
      on: { change: _vm.checkedChange },
      model: {
        value: _vm.checkList,
        callback: function ($$v) {
          _vm.checkList = $$v
        },
        expression: "checkList",
      },
    },
    [
      _c(
        "el-row",
        _vm._l(_vm.btnList, function (btn, index) {
          return _c(
            "el-col",
            { key: index, attrs: { span: 6 } },
            [
              _c("el-checkbox", { attrs: { label: btn.btnCode } }, [
                _vm._v(_vm._s(btn.btnName)),
              ]),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }