import TablePage from '../../../../components/table_page';
import Form from '../../components/process_approve/approve.vue';
import request from '../../../../utils/request';

export default {
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {};
  },
  created() {
    this.getConfigList('work-flow-copy');
  },
  methods: {
    modalClick({ val, row }) {
      const { code, name } = val;
      if (code === 'view') {
        this.modalConfig.title = name;
        this.modalConfig.type = 'EmptyDrawer';

        this.formConfig = {
          code,
          row,
        };

        this.openModal();
        request.get('/activiti/runtimeController/updateCopyTaskStatus', { id: row.id });
      }
    },
  },
};
