import TablePage from '../../../../components/table_page';
import Form from './form';

export default {
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {};
  },
  created() {
    this.getConfigList('work-flow-btns');
  },
  methods: {
    modalClick({ val, row }) {
      const { code, name } = val;

      if (code === 'add' || code === 'edit') {
        this.modalConfig.title = name;

        this.formConfig = {
          code,
          row,
        };
        this.openFull();
      }
    },
  },
};
