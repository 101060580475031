<template>
  <el-checkbox-group v-model="checkList" @change="checkedChange">
    <el-row>
      <el-col v-for="(btn, index) in btnList" :key="index" :span="6">
        <el-checkbox :label="btn.btnCode">{{btn.btnName}}</el-checkbox>
      </el-col>
    </el-row>
  </el-checkbox-group>
</template>

<script>
import request from '../../../../../utils/request';

export default {
  name: 'uploadMsg',
  props: {
    value: Array,
    code: String,
  },
  data() {
    return {
      btnList: [],
      checkList: [],
    };
  },
  watch: {
    value(val) {
      this.checkList = val || [];
    },
  },
  created() {
    if (this.code === 'edit') {
      this.getList();
    }
  },
  methods: {
    // 获取列表
    getList() {
      request.post('/activiti/taFuncRoleController/getAllBtn').then((res) => {
        this.btnList = res.result;
      });
    },
    // 监听选择
    checkedChange(val) {
      this.$emit('input', val);
    },
  },
};
</script>
