import request from '../../../../../utils/request';
import Form, { formCreate } from '../../../../../components/form';
import btnsGroup from './btn_group.vue';

formCreate.component('btnsGroup', btnsGroup);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'work-flow-btns-add',
    };
  },

  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'roleAuths') {
        v.props = {
          ...v.props,
          code: this.formConfig.code,
        };
      }

      return v;
    },

    // 表单渲染完成后回调
    formComplete() {
      this.hiddenFields(this.formConfig.code === 'add', 'roleAuths');

      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        const data = { ...this.formConfig.row };
        data.roleAuths = data.roleAuths ? data.roleAuths.split(',') : [];
        this.setValue(data);
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        let url = '/activiti/taFuncRoleController/saveBtnRole';
        const params = { ...formData };

        if (this.formConfig.code === 'edit') {
          url = '/activiti/taFuncRoleController/updateBtnRoleById';
          params.roleAuths = params.roleAuths.join(',');
          params.id = this.formConfig.row.id;
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message.success('操作成功');
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
